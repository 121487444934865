<template>
  <div class="container px-1">
    <div v-if="loading_i" class="d-flex flex-column justify-content-center align-items-center" style="min-height: 70vh">
      <i class="zmdi zmdi-spinner zmdi-hc-spin" style="font-size: 2rem; margin-right: 20px"></i>
    </div>

    <carousel :perPage="1" ref="carousel" :autoplay="false" :interval="0" :navigationEnabled="false" :loop="true"
      :paginationEnabled="false" navigationNextLabel="<i class='zmdi zmdi-chevron-right zmdi-hc-lg'></i>"
      navigationPrevLabel="<i class='zmdi zmdi-chevron-left zmdi-hc-lg'></i>">
      <slide v-for="station in stations" :key="station[0].id"
        style="max-width: 100% !important; min-width: 100% !important">
        <div>
          <!-- Location -->
          <div class="card mt-2 " style="box-shadow: 10px; border-radius: 10px; border: 0px">
            <div class="row" style="margin: 1px; border-radius: 10px;" :class="{ 'darkMode': isDarkMode }">
              <div class="my-1">
                <div class="row">
                  <div class="col-md-10 col-10 px-0">
                    <div style="
                        font-weight: 500;
                        font-size: 1.2em;
                        margin-left: 7px;
                      ">
                      {{ station[0].station_name }}
                    </div>
                  </div>
                  <div class="col-md-2 col-2 px-0 d-flex justify-content-end">
                    <span v-if="station[1].table.siaga == []"> </span>
                    <span v-else-if="station[1].table.siaga == 'MAINTENANCE'">
                      <img :src="mtc_i" class="statSZ" />
                    </span>
                    <span v-else-if="station[1].table.siaga == 'SIAGA 1'">
                      <img :src="s1_i" class="statSZ" />
                    </span>
                    <span v-else-if="station[1].table.siaga == 'SIAGA 2'">
                      <img :src="s2_i" class="statSZ" />
                    </span>
                    <span v-else-if="station[1].table.siaga == 'SIAGA 3'">
                      <img :src="s3_i" class="statSZ" />
                    </span>
                    <span v-else-if="station[1].table.siaga == 'OK'">
                      <img :src="ok_i" class="statSZ" />
                    </span>
                  </div>
                </div>

                <div>
                  {{ station[0].latitude }}<span>&#176;</span>, {{ station[0].longitude }}<span>&#176;</span>

                </div>

              </div>
            </div>
          </div>

          <div>
            <!-- Cards -->
            <div v-if="ava_width > 768" style="position: relative">
              <div v-for="card in [station[1]]" :key="card.id">
                <div style="border-radius: 15px;" class="row">
                  <div v-for="(item, index) in chart_f2(
                        card.chart.mix_status,
                        card.chart.chart_sensor,
                        card.chart.array_act_chart_type,
                        card.chart.chart_label,
                        card.chart.chart_data,
                        [card.chart.chart_date],
                        card.chart.array_act_icon,
                        card.chart.array_act_symbol
                      )" :key="'C' + index" class="col d-flex justify-content-center p-0 pb-3">
                    <div class="text-center comShadow" style="
                          background: #fff;
                          border-radius: 15px;
                          max-width: 14vw;
                        ">
                      <div class="text-white p-1 greenCard" style="min-width: 14vw">
                        <div class="iconSZ">
                          <img :src="`${$baseURL}${item.chart_icon[0]}`" height="70" />
                        </div>
                        <div class="fw-bold">
                          <div>{{ item.sensor[0][0] }}</div>
                        </div>
                        <div style="font-size: 0.9rem">
                          {{ formatDate(station[1].chart.chart_date) }},
                          {{ formatTime(station[1].chart.chart_date) }}
                        </div>
                      </div>
                      <div style="color: #46dddd">
                        <p class="p-0 m-0">
                          <span class="fw-bold mx-1" style="font-size: 1.7rem">{{ item.chart_data[0].slice(-1)[0]
                          }}</span>
                          <span style="font-size: 1rem">{{
                            item.symbol[0][0]
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Charts -->

            <div v-for="card in [station[1]]" :key="card.id">


              <div style="border-radius: 15px">

                <div class="box-sm border rounded mx-1 comShadow" style="border-radius: 15px">
                  <div>
                    <div class="px-2 pt-2">
                      {{ station[1].chart.chart_sensor[0][0][0] }} / {{ station[1].chart.chart_sensor[1][0][0] }} / {{
                        station[1].chart.chart_sensor[2][0][0] }} / {{ station[1].chart.chart_sensor[3][0][0] }}
                    </div>
                    <Chart style="height: 28vh" class="p-0 pr-0 pt-0 pb-0" :label="card.chart.chart_label[0][0]"
                      :chart-data="card.chart.chart_data[0][0]" :chart-data2="card.chart.chart_data[1][0]"
                      :chart-data3="card.chart.chart_data[2][0]" :chart-data4="card.chart.chart_data[3][0]"
                      :title1="`${card.chart.chart_sensor[0][0]} (${card.chart.array_act_symbol[0][0]})`"
                      :title2="`${card.chart.chart_sensor[1][0]} (${card.chart.array_act_symbol[1][0]})`"
                      :title3="`${card.chart.chart_sensor[2][0]} (${card.chart.array_act_symbol[2][0]})`"
                      :title4="`${card.chart.chart_sensor[3][0]} (${card.chart.array_act_symbol[3][0]})`" is="AWSChart">
                    </Chart>


                    <div class="row text-secondary pb-1" style="font-size: 0.8rem">
                      <div class="col-sm-6">
                        <span class="float-start" style="margin-top: -10px; margin-left: 5rem">
                          {{ formatPrevDate(card.chart.chart_date) }}
                        </span>
                      </div>
                      <div class="col-sm-6">
                        <span class="float-end mx-1" style="margin-top: -10px; padding-right: 4rem">
                          {{ formatDate(card.chart.chart_date) }}
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>



            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import AWSChart from "@/components/Chart/AWSChart";
// import BarChart from "@/components/Chart/BarChart";
// import LineChart from "@/components/Chart/LineChart";
// import MixChart from "@/components/Chart/MixChart"
import axios from "axios";
import mtc_i from "@/assets/icons/map/mtc.svg";
import ok_i from "@/assets/icons/map/ok.svg";
import s1_i from "@/assets/icons/map/s1.svg";
import s2_i from "@/assets/icons/map/s2.svg";
import s3_i from "@/assets/icons/map/s3.svg";

export default {
  name: "Slider",
  props: ["ava_width"],
  components: {
    // BarChart,
    // LineChart,
    // MixChart,
    AWSChart
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
    imageStyle() {
      const naturalWidth = this.$refs.image && this.$refs.image.naturalWidth;
      return {
        objectFit: 'cover',
        borderRadius: '10px 0px 0px 10px',
        height: '100%',
        width: naturalWidth && naturalWidth !== 100 ? '100%' : 'auto',
      };
    },
  },
  data() {
    return {
      stations: [],
      mtc_i,
      ok_i,
      s1_i,
      s2_i,
      s3_i,
      loading_i: true,
      window_loc: "",
      options: {
        arcColors: ["rgb(44,151,222)", "lightgray"],
        arcDelimiters: [80],
        rangeLabel: ["0%", "100%"],
        centralLabel: "70%",
      },
      currentIndex: 0,
    };
  },

  methods: {
    getNote(sensorData) {
      // Parse the string into an array
      const noteArray = JSON.parse(sensorData);

      // Return the last element of the array
      return noteArray;
    },
    getLastData(sensorData) {
      return sensorData[sensorData.length - 1];
    },
    formatDate(date) {
      var monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];
      var d = new Date(date),
        year = d.getFullYear(),
        month = "" + d.getMonth(),
        day = "" + d.getDate();

      if (day.length < 2) day = "0" + day;

      return [day, monthShortNames[month], year].join(" ");
    },
    formatPrevDate(date) {
      var monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];
      var days = -1;

      var d = new Date(date);

      var d_1 = new Date(d.getTime() + days * 24 * 60 * 60 * 1000),
        day_1 = "" + d_1.getDate(),
        month_1 = "" + d_1.getMonth(),
        year_1 = d_1.getFullYear();

      if (day_1.length < 2) day_1 = "0" + day_1;

      return [day_1, monthShortNames[month_1], year_1].join(" ");
    },
    formatTime(date) {
      var d = new Date(date),
        hour = "" + d.getHours(),
        minute = "" + d.getMinutes(),
        second = d.getSeconds();

      if (hour.length < 2) hour = "0" + hour;
      if (minute.length < 2) minute = "0" + minute;
      if (second.length < 2) second = "0" + second;

      return [hour, minute].join(":");
    },
    formatLabel(date) {
      var d = new Date(date),
        hour = "" + d.getHours(),
        minute = "" + d.getMinutes(),
        second = d.getSeconds();

      if (hour.length < 2) hour = "0" + hour;
      if (minute.length < 2) minute = "0" + minute;
      if (second.length < 2) second = "0" + second;

      return [hour, minute].join(":");
    },
    cards_f(a, b, c, d, e) {
      return a.map((card, i) => {
        return {
          label: card,
          icon: b[i],
          symbol: c[i],
          data: d[i],
          chart_type: e[i],
        };
      });
    },
    chart_f(a, b, c, d, e) {
      return a.map((card, i) => {
        return {
          label: card,
          chart_type: b[i],
          data: c[i],
          sensor: d[i],
          date: e[i],
        };
      });
    },

    chart_f2(a, b, c, d, e, f, g, h) {
      return a.map((card, i) => {
        return {
          mix: card,
          sensor: b[i],
          chart_type: c[i],
          chart_label: d[i],
          chart_data: e[i],
          chart_date: f[i],
          chart_icon: g[i],
          symbol: h[i],
        };
      });
    },
    chart_f3(a, b, c, d, e) {
      return a.map((card, i) => {
        return {
          mix: card,
          sensor: b[i],
          chart_type: c[i],
          chart_label: d[i],
          chart_data: e[i],
        };
      });
    },
    async loadHomeData() {
      try {
        const response = await axios.get(
          `${this.$baseURL}/home-data/non-auth/${this.balai}`
        );
        this.stations = response.data.map((element) => {
          const x = element[1].chart.chart_sensor.flat().length;
          const duration = Math.ceil(x / 3) * 3 * 4000 + 1000;
          element.duration = duration;
          return element;
        });

        this.loading_i = false;
      } catch (error) {
        // Handle error if necessary
        console.error("An error occurred:", error);
      }
    },

    async startSlideshow() {
      try {
        const response = await axios.get(
          `${this.$baseURL}/home-data/non-auth/${this.balai}`
        );
        this.stations = response.data.map((element) => {
          const x = element[1].chart.chart_sensor.flat().length;
          const duration = Math.ceil(x / 3) * 3 * 4000 + 1000;
          element.duration = duration;
          return element;
        });

        this.loading_i = false;
        let currentIndex = 0;

        const playSlide = () => {
          setTimeout(() => {
            currentIndex = (currentIndex + 1) % this.stations.length;
            if (this.$refs.carousel) {
              this.$refs.carousel.goToPage(currentIndex);
            }
            playSlide();
          }, this.stations[currentIndex].duration);
        };
        playSlide();
      } catch (error) {
        // Handle error if necessary
        console.error("An error occurred:", error);
      }
    },
  },
  mounted() {
    this.formatDate();
    this.formatLabel();
    this.formatTime();

    this.loadHomeData();
    setInterval(
      function () {
        this.loadHomeData();
      }.bind(this),
      15000
    );

    this.startSlideshow();
  },
  created() {
    let user = localStorage.getItem("user-info") || {};
    if (typeof user == "object") {
      this.balai = this.$fixedBalai;
    } else if (typeof user == "string") {
      this.role = JSON.parse(user).profile.role;
      this.user_id = JSON.parse(user).profile.user.id;
      this.balai = JSON.parse(user).profile.balai.id;
      if (this.role == "is_superuser") {
        this.balai = 0;
      }
    }
  },
};
</script>

<style scoped>
.statSZ {
  height: 1.5rem;
  margin-right: 5px;
}

.data-font {
  font-size: 2rem;
}

.symbol-font {
  font-size: 1.2rem;
}

.title-font {
  font-size: 1.1rem;
}

.root.padding {
  padding: 0;
}

.flex {
  display: flex;
  /* gap: 0.5rem; */
  flex-wrap: wrap;
}

.steps-wrapper {
  background-color: #232323;
}

.img-normal {
  height: 205px;
}

.img-awlr {
  height: 140px;
}

.min-vh-20 {
  min-height: 20vh;
}

@media only screen and (max-width: 768px) {
  .flex>div {
    flex: 100%;
  }
}

ul {
  list-style: none;
  display: table;
}

li {
  display: table-row;
}

b {
  display: table-cell;
  padding-right: 1em;
}

.comShadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.iconSZ img {
  resize: both;
  height: 10vh;
  max-height: 10vh;
}

/* Add your desired styles for the label and value containers */
.info-label {
  font-weight: 500;
  /* font-size: 0.9rem; */
  font-size: 1em;
}

.info-value {
  margin-left: 10px;
  font-size: 0.9em;
}

/* Dummy element style to hide if needed */
.info-dummy {
  margin-left: 10px;
  color: rgba(255, 255, 255, 0);
  font-size: 1em;
}

.imgSZ img {
  resize: both;
  height: 24vh;
  width: 190px;
}

@media (max-width: 768px) {
  .imgSZ {
    height: 170px;
    /* You can adjust the height as needed */
    width: 300px;
  }

  .info-dummy {
    display: none;
  }
}



.greenCl {
  color: #46dddd;
}
</style>

<style>
.highcharts-figure {
  min-width: 360px;
  max-width: 660px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}
</style>
