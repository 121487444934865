<template>
  <div class="row map">
    <!-- <p>Center is {{ center }}, zoom is {{ zoom }}</p> -->
    <div v-if="loading_i" class="d-flex flex-column justify-content-center align-items-center">
      <i class="zmdi zmdi-spinner zmdi-hc-spin" style="font-size: 2rem; margin-right: 20px"></i>
    </div>
    <!-- :zoom="computedZoom" -->
    <v-map :zoom="zoomx" :center="center" :options="{ attributionControl: false, zoomControl: false }"
      @update:zoom="zoomUpdated" @update:center="centerUpdated" style="border-radius: 15px" class="box"
      v-if="stations && !loading_i">
      <v-icondefault></v-icondefault>
      <v-tilelayer :url="currentUrl" :attribution="attribution"></v-tilelayer>
      <v-tilelayer v-if="balai === 9" :url="ulrLabel" :attribution="attribution"></v-tilelayer>

      <v-marker v-if="stations.station_type == 2" :lat-lng="latLng(stations.latitude, stations.longitude)">
        <v-icon>
          <img :src="awlr_im" class="iconSZ" />
        </v-icon>
        <v-popup>
          <div class="text-center">
            <img :src="awlr_i" class="imgSZ" />
            <div class="my-1" style="font-weight: 600">
              {{ stations.station_name }}
            </div>

            <router-link type="button" class="btn btn-success d-flex flex-row justify-content-center p-0"
              :to="`/station/update/${balai}/${stations.id}`" v-if="role == 'is_superuser' || role == 'is_staff'">
              <img :src="edit_i" class="mx-1" />
              <span style="font-size: 0.8rem">Edit</span></router-link>
          </div>
        </v-popup>
      </v-marker>
      <v-marker v-else-if="stations.station_type == 1" :lat-lng="latLng(stations.latitude, stations.longitude)">
        <v-icon>
          <img :src="arr_im" class="iconSZ" />
        </v-icon>
        <v-popup>
          <div class="text-center">
            <img :src="arr_i" class="imgSZ" />
            <div class="my-1" style="font-weight: 600">
              {{ stations.station_name }}
            </div>

            <router-link type="button" class="btn btn-success d-flex flex-row justify-content-center p-0"
              :to="`/station/update/${balai}/${stations.id}`" v-if="role == 'is_superuser' || role == 'is_staff'">
              <img :src="edit_i" class="mx-1" />
              <span style="font-size: 0.8rem">Edit</span></router-link>
          </div>
        </v-popup>
      </v-marker>
      <v-marker v-else-if="stations.station_type == 3" :lat-lng="latLng(stations.latitude, stations.longitude)">
        <v-icon>
          <img :src="aws_im" class="iconSZ" />
        </v-icon>
        <v-popup>
          <div class="text-center">
            <img :src="aws_i" class="imgSZ" />
            <div class="my-1" style="font-weight: 600">
              {{ stations.station_name }}
            </div>

            <router-link type="button" class="btn btn-success d-flex flex-row justify-content-center p-0"
              :to="`/station/update/${balai}/${stations.id}`" v-if="role == 'is_superuser' || role == 'is_staff'">
              <img :src="edit_i" class="mx-1" />
              <span style="font-size: 0.8rem">Edit</span></router-link>
          </div>
        </v-popup>
      </v-marker>

      <v-control-scale position="topright" :imperial="false" :metric="true"
        v-if="stations.station_type != 3"></v-control-scale>
    </v-map>
  </div>
</template>

<script>
import * as Vue2Leaflet from "vue2-leaflet";
import awlr_im from "@/assets/icons/map/mawlr.svg";
import arr_im from "@/assets/icons/map/marr.svg";
import aws_im from "@/assets/icons/map/maws.svg";
import awlr_i from "@/assets/icons/map/awlr.svg";
import arr_i from "@/assets/icons/map/arr.svg";
import aws_i from "@/assets/icons/map/aws.svg";
import edit_i from "@/assets/icons/map/edit.svg";
import mtc_i from "@/assets/icons/map/mtc.svg";
import ok_i from "@/assets/icons/map/ok.svg";
import s1_i from "@/assets/icons/map/s1.svg";
import s2_i from "@/assets/icons/map/s2.svg";
import s3_i from "@/assets/icons/map/s3.svg";

export default {
  name: "TableMap",
  props: ["stations", "loading_i", "status"],

  components: {
    "v-map": Vue2Leaflet.LMap,
    "v-tilelayer": Vue2Leaflet.LTileLayer,
    "v-icondefault": Vue2Leaflet.LIconDefault,
    "v-marker": Vue2Leaflet.LMarker,
    "v-popup": Vue2Leaflet.LPopup,
    "v-control-scale": Vue2Leaflet.LControlScale,
    "v-icon": Vue2Leaflet.LIcon,

  },


  data() {
    return {
      url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
      urlPDAM: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      ulrLabel: 'https://tiles.stadiamaps.com/tiles/stamen_toner_labels/{z}/{x}/{y}{r}.png',
      attribution: "",
      zoomx: 18,
      center: undefined,
      balai: "",
      clusterOptions: {},
      awlr_im,
      arr_im,
      aws_im,
      awlr_i,
      arr_i,
      aws_i,
      edit_i,
      mtc_i,
      ok_i,
      s1_i,
      s2_i,
      s3_i,
    };
  },
  computed: {
    computedZoom() {
      // Check if stations and stations.station_type are available and meet the condition
      if (this.stations && this.stations.station_type === 3) {
        return 6; // Zoom level when station_type is 3
      } else {
        return this.zoom; // Return the zoom value set in the mounted hook for other cases
      }
    },
    currentUrl() {
      // Use the appropriate URL based on the value of balai
      return this.balai === 9 ? this.urlPDAM : this.url;
    },
  },
  methods: {
    click: (e) => console.log("clusterclick", e),
    ready: (e) => console.log("ready", e),
    latLng: function (lat, lng) {
      return [lat, lng];
    },
    centerUpdated(center) {
      this.center = center;
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    formatDate(date) {
      var monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];
      var d = new Date(date),
        year = d.getFullYear(),
        month = "" + d.getMonth(),
        day = "" + d.getDate();

      if (day.length < 2) day = "0" + day;
      return [day, monthShortNames[month], year].join(" ");
    },
    formatTime(date) {
      var d = new Date(date),
        hour = "" + d.getHours(),
        minute = "" + d.getMinutes(),
        second = d.getSeconds();

      if (hour.length < 2) hour = "0" + hour;
      if (minute.length < 2) minute = "0" + minute;
      if (second.length < 2) second = "0" + second;

      return [hour, minute].join(":");
    },
  },

  created() {
    this.gAuthStation();
  },
  async mounted() {
    this.zoom = this.detBalai.map_zoom;
    // this.center = [this.detBalai.latitude, this.detBalai.longitude];
    // console.log(this.zoom);

    this.center = [this.stations.latitude, this.stations.longitude];

    // console.log(this.center);
    // console.log(this.stations.latitude);
    setTimeout(() => {
      this.$nextTick(() => {
        this.clusterOptions = { disableClusteringAtZoom: 11 };
      });
    }, 5000);
  },
};
</script>

<style scoped>
.map {
  /* height: 35vh; */
  height: 285px;
}

@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.example-custom-control {
  background: #fff;
}

.imgSZ {
  height: 38px;
}

.iconSZ {
  height: 24px;
}

.statSZ {
  height: 22px;
}
</style>

<style scoped src="@/assets/css/figma.css"></style>
