

<script>
import L from "leaflet";
import "leaflet.heat";
import { findRealParent } from "vue2-leaflet";

export default {
  name: "Firemap",
  props: {
    gradient: {
      type: Object,
      custom: true,
    },
    fireData: {
      type: Array,
    },
    vehicles: {
      type: Array,
      required: true,
    },
    minOpacity: {
      type: Number,
      custom: true,
    },
    maxZoom: {
      type: Number,
      custom: true,
    },
    radius: {
      type: Number,
      custom: true,
    },
    blur: {
      type: Number,
      custom: true,
    },
    max: {
      type: Number,
      custom: true,
    },
    visible: {
      type: Boolean,
      custom: true,
      default: true,
    },
  },
  data() {
    return {
      ready: false,
      // latlngs: this.processData(this.vehicles),
      latlngs: this.fireData
    };
  },
  
  mounted() {
    const options = {
      minOpacity: this.minOpacity,
      gradient: this.gradient,
      maxZoom: this.maxZoom,
      radius: this.radius,
      blur: this.blur,
      max: this.max,
    };

    this.mapObject = L.heatLayer(this.latlngs, options);
    L.DomEvent.on(this.mapObject, this.$listeners);
    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this, !this.visible);
  },
  beforeDestroy() {
    this.parentContainer.removeLayer(this);
  },
  watch: {
    vehicles(newVal) {
      // Update latlngs when vehicles prop changes
      this.latlngs = this.processData(newVal);
      this.mapObject.setLatLngs(this.latlngs);
    },
    visible(newVal) {
      this.toggleVisibility(newVal);
    },
  },
  methods: {
    processData(vehicles) {
      const from = new Date("06/02/2021");
      const to = new Date(new Date("06/03/2021") - 1);

      return vehicles.map((v) => {
        const { lat, lng } = v.location;
        const ratio =
          (Math.min(new Date(v.lastSeen), to) - Math.max(new Date(v.firstSeen), from)) /
          (to - from);

        return [lat, lng, ratio];
      });
    },
    setMinOpacity(newVal) {
      this.mapObject.setOptions({ minOpacity: newVal });
    },
    setGradient(newVal) {
      this.mapObject.setOptions({ gradient: newVal });
    },
    setMaxZoom(newVal) {
      this.mapObject.setOptions({ maxZoom: newVal });
    },
    setRadius(newVal) {
      this.mapObject.setOptions({ radius: newVal });
    },
    setBlur(newVal) {
      this.mapObject.setOptions({ blur: newVal });
    },
    setMax(newVal) {
      this.mapObject.setOptions({ max: newVal });
    },
    setVisible(newVal) {
      // if (newVal) return;
      if (newVal) {
        this.parentContainer.addLayer(this);
      } else {
        this.parentContainer.removeLayer(this);
      }
    },
    addLatLng(value) {
      this.mapObject.addLatLng(value);
    },
    toggleVisibility(visible) {
      if (visible) {
        this.parentContainer.addLayer(this);
      } else {
        this.parentContainer.removeLayer(this);
      }
    },
  },
};
</script>