<!-- <template>
  <div>
    <div class="row">
      <div class="col-12">
        <StationMap :stations="stations" :status="status" :loading_i="loading_i" class="mx-auto mt-2 shadow-sm border"
          style="border-radius: 15px" />
      </div>
      <div class="col-12">
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import StationMap from "@/components/Map/StationMap";

export default {
  name: "Map",

  components: {
    StationMap,
  },
  data() {
    return {
      role: null,
      balai: null,
      stations: [],
      normalIcon: [18, 18],
      largeIcon: [25, 25],
      marker: null,
      loading_i: true,
      status: [],
    };
  },

  methods: {
    mouseOverstation: function (e) {
      this.stations[e][0].iconSize = this.largeIcon;
    },
    mouseLeftstation: function (e) {
      this.stations[e][0].iconSize = this.normalIcon;
    },
    async loadData() {
      await axios
        .get(`${this.$baseURL}/home-data/non-auth/${this.balai}`)
        .then((r) => {
          this.stations = r.data.map((r) => {
            r[0].iconSize = this.normalIcon;
            return r;
          });
          if (r.status == 200) {
            this.loading_i = false;
          }
        });

      await axios
        .get(`${this.$baseURL}/home-data/non-auth/${this.balai}`)
        .then((r) => {
          this.status = r.data;
          // console.log(this.status);
        })
        .catch(function (e) {
          console.log(e);
        });
    },
  },
  created() {
    let user = localStorage.getItem("user-info") || {};
    if (typeof user == "object") {
      this.balai = this.$fixedBalai;
    } else if (typeof user == "string") {
      this.role = JSON.parse(user).profile.role;
      this.user_id = JSON.parse(user).profile.user.id;
      this.balai = JSON.parse(user).profile.balai.id;
      if (this.role == "is_superuser") {
        this.balai = 0;
      }
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>
 -->


<template>
  <div>
    <input type="checkbox" v-model="showFire" /> Show Fire
    <div>
      <l-map ref="map" style="height: 80vh;" :zoom="zoom" :center="center" @update:zoom="zoomUpdated"
        :options="{ attributionControl: false, zoomControl: false }" @update:center="centerUpdated"
        @update:bounds="boundsUpdated">
        <l-tile-layer :url="url" :options="options"></l-tile-layer>
        <l-tile-layer :url="label"></l-tile-layer>

        <Firemap :vehicles="vehicles" :fireData="fireData" :radius="25" :blur="15" :minOpacity="minOpacity" :max="1"
          :maxZoom="18" :visible="showFire" />
      </l-map>
      <div>zoom: {{ zoom }}, center: {{ center }}</div>
    </div>
  </div>
</template>

<script>
import Firemap from "@/components/Map/Firemap";
import {
  LMap, LTileLayer,
  // LMarker, LRectangle, LTooltip 
} from "vue2-leaflet";
import L from 'leaflet';
import 'leaflet-velocity';
import axios from 'axios';
import vehicles from "@/data/hausen.json";
import fires from "@/data/fireData.json";

export default {
  components: {
    LMap, LTileLayer,
    // LMarker,  LRectangle, LTooltip, 
    Firemap
  },
  data() {
    return {
      markers: [
        { lat: -7.5, lng: 143.0, popupContent: 'Marker 1' },
        { lat: -28.5, lng: 156.0, popupContent: 'Marker 2' },
        // { lat: -7.5, lng: 143.0, popupContent: 'Marker 3' },
        // { lat: -28.5, lng: 156.0, popupContent: 'Marker 4' },
        // Add more markers as needed
      ],
      windDataTable: [],
      vehicles: [],
      fireData: [],
      windData: null,
      showGrid: false,
      showMarkers: false,
      // url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",

      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      // url: "https://{s}.tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png",
      // url: "https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
      label: "https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png",
      options: {

        apikey: 'choisirgeoportail',
        format: 'image/jpeg',
      },
      // zoom: 14,
      zoom: 12,
      // zoom: 12, center: LatLng(-1.862541, 125.472259)
      // center: [50.123444, 8.637929],
      center: [-1.862541, 125.472259],
      minOpacity: 0.2,
      bounds: null,
      showVehicles: true,
      showFire: true,
      showWind: true,
      gradient: {
        0: "red",
        0.2: "purple",
        0.5: "blue",
        0.95: "cyan",
      },
      velocityLayer: null,

    };
  },
  mounted() {
    this.vehicles = vehicles;
    this.fireData = fires;
    // this.loadData('wind-global.json', 'Wind Global');
    this.loadData('wind-gbr.json', 'Wind gbr');
    this.handleWindData(this.windData);
  },
  watch: {
    windData: {
      handler(newData) {
        this.handleWindData(newData);
        this.updateWindDataTable(newData);
      },
      immediate: true,
    },
    showWind: "toggleWindData",
  },
  computed: {
    gridMarkers() {
      const markers = [];
      const gridInterval = 1;

      for (let lat = -7.5; lat >= -28.5; lat -= gridInterval) {
        for (let lng = 143.0; lng <= 156.0; lng += gridInterval) {
          // Calculate the center of each grid
          const centerLat = lat - gridInterval / 2;
          const centerLng = lng + gridInterval / 2;

          markers.push({
            lat: centerLat,
            lng: centerLng,
            popupContent: `Grid Marker ${markers.length + 1}`,
          });
        }
      }

      return markers;
    },
    heatmapData() {
      return this.showVehicles ? this.processData(this.vehicles) : [];
    },
    firemapData() {
      return this.showFire ? this.fireData : [];
    },
    gridRectangles() {
      // Define the grid properties and calculate bounds for each rectangle
      const gridOptions = { interval: 1, color: 'black', weight: 1, opacity: 0.5, fill: true, fillOpacity: 0.2 };
      const rectangles = [];

      for (let lat = -7.5; lat >= -28.5; lat -= gridOptions.interval) {
        for (let lng = 143.0; lng <= 156.0; lng += gridOptions.interval) {
          rectangles.push({
            bounds: [
              [lat, lng],
              [lat - gridOptions.interval, lng + gridOptions.interval],
            ],
            color: gridOptions.color,
            weight: gridOptions.weight,
            opacity: gridOptions.opacity,
            fill: gridOptions.fill,
            fillOpacity: gridOptions.fillOpacity,
          });
        }
      }

      return rectangles;
    },
  },
  methods: {
    normalizeWindDirection(direction) {
      // Convert negative degrees to positive and ensure it's within the 0-360 range
      return ((direction % 360) + 360) % 360;
    },
    updateWindDataTable(data) {
      if (data && data.length === 2) {
        const eastwardWind = data[0].data;
        const northwardWind = data[1].data;

        // Calculate wind speed and wind direction
        const windSpeed = eastwardWind.map((u, i) => Math.sqrt(u ** 2 + northwardWind[i] ** 2));
        const windDirection = eastwardWind.map((u, i) => Math.atan2(northwardWind[i], u) * (180 / Math.PI));

        // Create an array of objects for the table
        this.windDataTable = windSpeed.map((speed, index) => ({
          eastward: eastwardWind[index].toFixed(2),
          northward: northwardWind[index].toFixed(2),
          windSpeed: speed.toFixed(2),
          windDirection: windDirection[index].toFixed(2),
        }));
      }
    },
    handleWindData(data) {
      if (this.velocityLayer) {
        this.velocityLayer.remove();
      }

      // Create a new velocity layer with the updated data
      this.velocityLayer = L.velocityLayer({
        displayValues: true,
        displayOptions: {
          velocityType: 'Wind gbr',
          position: 'bottomleft',
          emptyString: 'No wind gbr data',
          showCardinal: true,
        },
        data: data,
        // OPTIONAL
        minVelocity: 0, // used to align color scale
        maxVelocity: 10, // used to align color scale
        velocityScale: 0.01, // modifier for particle animations, arbitrarily defaults to 0.005
        opacity: 0.97, // layer opacity, default 0.97
        // colorScale: ['green','blue', 'yellow', 'orange', 'red']
        // colorScale: [
        //   '#FFFFFF',  // No wind (white)
        //   '#00FFFF',  // Low wind speed (cyan)
        //   '#0000FF',  // Moderate wind speed (blue)
        //   '#008000',  // Strong wind speed (green)
        //   '#FFFF00',  // Very strong wind speed (yellow)
        //   '#FFA500',  // High wind speed (orange)
        //   '#FF0000'   // Extremely high wind speed (red)
        // ]
      });

      this.addToMap(this.velocityLayer);


    },
    toggleWindData() {
      if (this.showWind) {
        this.addToMap(this.velocityLayer);
      } else {
        this.removeFromMap(this.velocityLayer);
      }
    },
    addToMap(layer) {
      if (this.$refs.map && this.$refs.map.mapObject) {
        layer.addTo(this.$refs.map.mapObject);
      } else {
        // console.error('Leaflet map object is not available.');
      }
    },
    removeFromMap(layer) {
      if (this.$refs.map && this.$refs.map.mapObject) {
        layer.remove();
      } else {
        // console.error('Leaflet map object is not available.');
      }
    },
    async loadData(fileName) {
      try {
        const { data } = await axios.get(`/data/${fileName}`);
        this.windData = data;

        if (this.$refs.intensityMap) {
          this.$refs.intensityMap.handleWindData(data);
        }
      } catch (error) {
        console.error("Error loading JSON:", error);
      }
    },
    processData(vehicles) {
      const from = new Date("06/02/2021");
      const to = new Date(new Date("06/03/2021") - 1);

      return vehicles.map((v) => {
        const { lat, lng } = v.location;
        const ratio =
          (Math.min(new Date(v.lastSeen), to) - Math.max(new Date(v.firstSeen), from)) /
          (to - from);

        return [lat, lng, ratio];
      });
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
  },
};
</script>

<style>
.leaflet-control {
  color: white;

}
</style>

<style scoped>
td {
  padding: 0 !important;
  margin: 0 !important;
}
</style>