<template>
  <div class="mobile-width">
    <Header />
    <div v-if="loading_i" class="center-container mt-2">
      <div class="content-container">
        <div class="icon-container">
          <i class="zmdi zmdi-spinner zmdi-hc-spin" style="font-size: 2rem"></i>
        </div>
        <p class="mt-3">Fetching data, please wait...</p>
      </div>
    </div>
    <div v-else>
      <div class="bwsTitle mobile-top d-flex justify-content-between" :class="{ 'darkMode': isDarkMode }">
        <div v-if="balai !== 9" style="text-transform: uppercase;">
          {{ balai_name }}
        </div>
        <div v-if="balai === 9" class="d-flex align-items-center text-uppercase mt-1">
          <img :src="logoMalang" style="width: 20px;" />
          <span class="mx-2">PERUMDA TIRTA KANJURUHAN</span>
        </div>
        <div style="font-size: 1rem; align-self: flex-end;" v-if="balai == 9">
          {{ currentDateTime }}
        </div>
      </div>
      <div v-if="loading_i" class="d-flex flex-column justify-content-center align-items-center mt-2">
        <i class="zmdi zmdi-spinner zmdi-hc-spin" style="font-size: 2rem; margin-right: 20px"></i>
      </div>

      <div class="d-flex flex-row" v-if="ava_width > 768">
        <marquee-text :repeat="text_repeat" :duration="custom_duration" :paused="isPaused"
          class=" mt-0 pb-1 box custom-col-md" :class="{ 'darkMode': isDarkMode }" @mouseenter="isPaused = !isPaused"
          @mouseleave="isPaused = false">
          <span v-for="station in stations" :key="station[0].id" style="font-size: 1.35rem">
            <span style="font-weight: 500" class="bwsTitle" :class="{ 'darkMode': isDarkMode }">
              {{ station[0].station_name }} &bull;
            </span>
            <span v-if="station[2]">
              <span v-for="(card, index) in [station[2]]" :key="index" class="flex mt-2" style="min-height: 12vh">
                <span v-for="(label, labelIndex) in card.running_label" :key="labelIndex" class="bwsTitle"
                  :class="{ 'darkMode': isDarkMode }">
                  {{ label }}: {{ card.running_data[labelIndex] }}
                  {{ card.running_symbol[labelIndex] }}
                  <span v-if="labelIndex !== card.running_label.length - 1">
                    |
                  </span>
                </span>
              </span>
            </span>
            <img v-if="$app_title == 'WEATHER-VUE'" :src="logoBT" style="width: 20px" class="mx-3 my-1" />
            <img v-else :src="logoPU" style="width: 20px" class="mx-3 my-1" />
          </span>
        </marquee-text>
      </div>

      <div class="row">
        <div class="col" :class="{ 'col-6': ava_width > 768 }">
          <Map />
        </div>
        <div class="col" :class="{ 'col-6 p-0': ava_width > 768 }">
          <Slider :ava_width="ava_width" />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Public/Header";
import logoPU from "@/assets/logo_pupr.svg";
import logoMalang from "@/assets/img/logo_malang.png";
import logoBT from "@/assets/icons/logo-bt.svg";

import MarqueeText from "@/assets/MarqueeText.vue";
import Map from "@/components/Map/Map.vue";
import Slider from "@/components/Chart/Slider.vue";
import axios from "axios";

export default {
  components: {
    Header,
    MarqueeText,
    Map,
    Slider,
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
  data() {
    return {
      stations: [],
      isPaused: false,
      text_duration: null,
      text_repeat: null,
      logoPU,
      logoMalang,
      logoBT,
      ava_width: null,
      ava_height: null,
      balai: null,
      loading_i: true,
      balai_name: "",
      custom_duration: null,
      showCarousel: true,
      currentDateTime: 12,
    };
  },
  methods: {
    async checkScreen() {
      this.ava_width = screen.availWidth;
      this.ava_height = screen.availHeight;
      // console.log('w: '+ this.ava_width + ', h: ' + this.ava_height);
    },
    marquee_f(a, b, c, d) {
      return a.map((card, i) => {
        return {
          sensor: card,
          data: b[i],
          symbol: c[i],
          siaga: d[i],
          role: "is_guess",
        };
      });
    },
    marquee_g(a, b, c) {
      return a.map((card, i) => {
        return {
          sensor: card,
          data: b[i],
          symbol: c[i],
          role: "is_guess",
        };
      });
    },
    async homeData() {
      var st_name = [];
      var sensor = [];
      var st_name_length = null;
      var sensor_length = null;

      await axios
        .get(`${this.$baseURL}/home-data/non-auth/${this.balai}`)
        .then((r) => {
          r.data.forEach((e) => {
            st_name.push(e[0].station_name.length);
            sensor.push(
              e[1].table.array_table_label.reduce((a, b) => a + b, 0).length
            );

            var x =
              e[0].station_name.length +
              e[1].table.array_table_label.reduce((a, b) => a + b, 0).length;

            e.duration = x * 510;
          });

          st_name_length = st_name.reduce((a, b) => a + b, 0);
          sensor_length = sensor.reduce((a, b) => a + b, 0);
          this.stations = r.data;

          this.custom_duration = (st_name_length + sensor_length) / 2;
          this.stations.slice(-1).pop().duration + 200;

          if (r.status == 200) {
            this.loading_i = false;
          }
        });

      let currentIndex = 0;

      const playSlide = () => {
        setTimeout(() => {
          currentIndex = (currentIndex + 1) % this.stations.length;

          if (this.$refs.carousel) {
            this.$refs.carousel.goToPage(currentIndex);
          }
          playSlide();
        }, this.stations[currentIndex].duration);
      };

      playSlide();
    },
    getCurrentDate() {
      const dateObject = new Date();

      // Format date
      const dateOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'Asia/Jakarta',
      };

      const dateString = dateObject.toLocaleString('id-ID', dateOptions);

      // Format time manually
      const hours = dateObject.getHours().toString().padStart(2, '0');
      const minutes = dateObject.getMinutes().toString().padStart(2, '0');
      const seconds = dateObject.getSeconds().toString().padStart(2, '0');

      const timeString = `${hours}:${minutes}:${seconds}`;

      // Combine date and time
      this.currentDateTime = `${dateString} ${timeString}`;
    },
  },
  async created() {
    this.balai_name = document.title;
    let user = localStorage.getItem("user-info") || {};

    if (typeof user == "object") {
      this.balai = this.$fixedBalai;

      await axios
        .get(`${this.$baseURL}/balai/non-auth/${this.balai}`)
        .then((r) => {
          this.detBalai = r.data[0];
        });
      this.text_duration = this.detBalai.text_duration;
      this.text_repeat = this.detBalai.text_repeat;
      this.homeData();
    } else if (typeof user == "string") {
      this.role = JSON.parse(user).profile.role;
      this.user_id = JSON.parse(user).profile.user.id;
      this.balai = JSON.parse(user).profile.balai.id;
      this.token = JSON.parse(user).token;

      await axios
        .get(`${this.$baseURL}/balai/${this.balai}`, {
          headers: {
            Authorization: `Token ${this.token}`,
          },
        })
        .then((r) => {
          this.detBalai = r.data;
        });
      this.text_duration = this.detBalai.text_duration;
      this.text_repeat = this.detBalai.text_repeat;
      this.homeData();
    }


  },
  async mounted() {
    this.checkScreen();
    // console.log('test home');
    this.getCurrentDate()
    // Set up a timer to call getCurrentDate every second
    this.timer = setInterval(() => {
      this.getCurrentDate();
    }, 1000);

  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed to prevent memory leaks
    clearInterval(this.timer);
  },
};
</script>

<style scoped src="@/assets/css/flex14.css"></style>

<style>
.mobile-width {
  max-width: 90%;
  margin-left: 90px;
}

.mobile-top {
  margin: 0px 0px 5px;
}

@media (max-width: 768px) {
  .mobile-width {
    max-width: 97%;
    margin: auto;
  }

  .mobile-top {
    margin-top: 5px;
  }
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Center vertically within viewport */
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-container {
  margin-right: 20px;
}
</style>